<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
      :key="Date.now()"
    >
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item"
          color="primary"
          :class="$route.path !== '/' ? `item-${i}` : null"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.t(item.view) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
<style scoped>
.item-0::before {
  opacity: 0;
}

.item-0 .v-list-item__title {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
<i18n>
{
	"en": {
		"eco-friendly": "Eco-Friendly",
		"home": "Home",
		"interventi": "Interventions",
		"servizi": "Services",
		"sicurezza": "Security"
	},
	"it": {
		"eco-friendly": "Eco-Friendly",
		"home": "Home",
		"interventi": "Modalità",
		"servizi": "Servizi",
		"sicurezza": "Sicurezza"
	}
}
</i18n>
